.successMain {
    margin-top: 60px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.successTitle {
    font-size: 32px;
    font-weight: 800;
    margin-bottom: 20px;
    color: #fff;
    text-align: center;
}