.root {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.video {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

@media screen and (max-width: 768px) {
    .video {
        width: unset;
    }

    .root {
        width: 90vw;
        margin: 0 auto;
    }

    h4 {
        text-align: center;
    }

    h6 {
        text-align: center;
    }
}

.logo {
    width: 100%;
    margin-top: 60px;
    max-width: 900px;
}