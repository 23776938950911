.heroMain {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 80px;
}

.title {
    margin-bottom: 40px;
    width: 100%;
    font-size: 72px;
    text-align: center;
    color: #fff;
}

.subtitle {
    font-size: 28px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
}

.caption {
    font-size: 20px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
}

.rsvpBtn {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 800;
    background-color: #fff;
    box-shadow: none;
    border: 1px solid #000;
    outline: none;
    padding: 8px 48px;
    transition: 300ms ease-in;
    cursor: pointer;
    color: #000;
    text-decoration: none;
}

.rsvpBtn:hover {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
}

@media screen and (max-width: 960px) {

    .heroMain {
        margin-top: 20vh;
    }

    .title {
        font-size: 32px;
    }

    .subtitle {
        font-size: 20px;
    }

    .lastSubtitle {
        margin-bottom: 40px;
    }

    .caption {
        font-size: 16px;
    }

    .rsvpBtn {
        margin-top: 40px;
    }
    
}