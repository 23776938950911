.main {
    margin-top: 60px;
    width: 100%;
    max-width: 600px;
}

.formSubtitle {
    font-size: 28px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 40px;
}

.inputContainer {
    margin-bottom: 32px;
    width: 100%;
}

.inputTitle {
    font-size: 14px;
    color: #fff;
    margin-bottom: 8px;
}

.inputField {
    background-color: #fff;
    border: none;
    outline: none;
    font-size: 14px;
    padding: 8px;
    width: 100%;
}

.submitBtn {
    font-size: 16px;
    font-weight: 800;
    background-color: #fff;
    box-shadow: none;
    border: 1px solid #000;
    outline: none;
    padding: 8px 48px;
    transition: 300ms ease-in;
    cursor: pointer;
}

.submitBtn:hover {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
}

.formCaption {
    color: #fff;
    font-size: 14;
    margin-top: 12px;
    margin-bottom: 20px;
}

.formError {
    color: #d32f2f;
    font-size: 12px;
}

@media screen and (max-width: 960px) {

    .formSubtitle {
        font-size: 20px !important;
        text-align: left;
    }

    .submitBtn {
        width: 100%;
    }
    
}